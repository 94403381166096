"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CmykFilter = void 0;
const utils_1 = require("@src/shared/utils");
const ng = window.angular;
function CmykFilter() {
    return ([rgb, a]) => {
        //@ts-ignore
        const cmyk = (0, utils_1.rgb2cmyk)([rgb, a]);
        cmyk.toString = function () {
            const color = this[0].map((item) => item.toFixed(0)).join(', ');
            return color + ((this[1].toFixed(2) < 1) ? `, ${this[1].toFixed(2)}` : '');
        };
        return cmyk;
    };
}
exports.CmykFilter = CmykFilter;
CmykFilter.$inject = [];
exports.default = CmykFilter;
